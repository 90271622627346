<template>
  <div class="form-group">
    <label
      v-if="label !== null"
      :for="`form-${_uid}`"
    >
      {{ label }}
    </label>
    <editor-menu-bar
      v-slot="{ commands, isActive }"
      class="mb-1"
      :editor="editor"
    >
      <div class="tiptap-menubar">
        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.bold() }"
          @click.prevent="commands.bold"
        >
          <CIcon :content="$options.icons.cilBold" />
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.italic() }"
          @click.prevent="commands.italic"
        >
          <CIcon :content="$options.icons.cilItalic" />
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.strike() }"
          @click.prevent="commands.strike"
        >
          <CIcon :content="$options.icons.cilTextStrike" />
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.underline() }"
          @click.prevent="commands.underline"
        >
          <CIcon :content="$options.icons.cilUnderline" />
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.heading({ level: 1 }) }"
          @click.prevent="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.heading({ level: 2 }) }"
          @click.prevent="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.heading({ level: 3 }) }"
          @click.prevent="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.bullet_list() }"
          @click.prevent="commands.bullet_list"
        >
          <CIcon :content="$options.icons.cilList" />
        </button>

        <button
          class="tiptap-menubar__button"
          :class="{ 'tiptap-menubar__button--active': isActive.ordered_list() }"
          @click.prevent="commands.ordered_list"
        >
          <CIcon :content="$options.icons.cilListNumbered" />
        </button>

        <button
          class="tiptap-menubar__button"
          @click.prevent="commands.undo"
        >
          <CIcon :content="$options.icons.cilActionUndo" />
        </button>

        <button
          class="tiptap-menubar__button"
          @click.prevent="commands.redo"
        >
          <CIcon :content="$options.icons.cilActionRedo" />
        </button>
      </div>
    </editor-menu-bar>

    <editor-content
      :editor="editor"
      :class="{
        'is-invalid': isValid === false,
      }"
      @:input="innerValue = $event"
    />
    <div
      v-if="invalidFeedback"
      class="invalid-feedback"
    >
      {{ invalidFeedback }}
    </div>
  </div>
</template>
<script>
import {
  cilBold,
  cilItalic,
  cilTextStrike,
  cilUnderline,
  cilList,
  cilListNumbered,
  cilActionUndo,
  cilActionRedo,
  cilDoubleQuoteSansLeft,
} from '@coreui/icons';
import { EditorContent, Editor, EditorMenuBar } from 'tiptap';
import {
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  icons: {
    cilBold,
    cilItalic,
    cilTextStrike,
    cilUnderline,
    cilList,
    cilListNumbered,
    cilDoubleQuoteSansLeft,
    cilActionUndo,
    cilActionRedo,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    invalidFeedback: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      emitAfterOnUpdate: false,
      editor: null,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    editorAttributes() {
      return {
        id: `form-${this._uid}`,
        class: [
          'form-control',
          'tiptab__editor',
          ...(this.isValid !== false ? [] : ['is-invalid']),
        ].join(' '),
      };
    },
  },
  watch: {
    editorAttributes() {
      // trigger call of editorAttributes from editor.view
      this.editor.setOptions({});
    },
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) {
        this.editor.setContent(val);
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.innerValue,
      extensions: [
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      onUpdate: ({ getHTML }) => {
        this.emitAfterOnUpdate = true;
        this.innerValue = getHTML();
      },
      editorProps: {
        attributes: () => this.editorAttributes,
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
